@tailwind base;

/* Your own custom base styles */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Your own custom component styles */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

html,body, #root {
    min-height: 100%;
}

/* Your own custom utilities */
dl {
    dt {
        @apply text-sm font-bold pt-2;
    }

    dd {
        @apply pl-4 text-sm;
    }
}
